<template>
<!-- v-if="$can('read', 'Analytics')" -->
  <div >
    <b-row class="match-height">
      <b-col lg="12">
        <b-overlay
          :show="overlayStatus"
          rounded="sm"
        >
          <b-card>
            <b-card-title class="card-header">
              <h2>Vehículos en tiempo real</h2>
            </b-card-title>
            <b-row
              align-h="center"
            >
              <b-col
                lg="5"
                sm="12"
                class="mb-2"
              >
                <b-form-group
                  label="Vehículo"
                  label-for="i-idVehicle"
                >
                  <v-select
                    label="name"
                    :options="vehicles"
                    :clearable="false"
                    v-model="idVehicle"
                    :reduce="name => name.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                lg="3"
                sm="12"
                class="mb-2"
              >
                <b-form-group
                  label="Fecha inicial"
                  label-for="i-initialDate"
                >
                  <flat-pickr
                    v-model="initialDate"
                    class="form-control invoice-edit-input"
                  />
                </b-form-group>
              </b-col>
              <b-col
                lg="3"
                sm="12"
                class="mb-2"
              >
                <b-form-group
                  label="Fecha final"
                  label-for="i-finalDate"
                >
                  <flat-pickr
                    v-model="finalDate"
                    class="form-control invoice-edit-input"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="1"
              >
                <b-button
                  id="realod"
                  variant="gradient-primary"
                  size="sm"
                  class="btn-icon"
                  style="margin-top: 2em;"
                  @click="getHistoryLocations()"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-col>
            </b-row>
            <GmapMap
              v-if="data.length > 0"
              :center='center'
              :zoom='17'
              ref="map"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUI: false
              }"
              style='width:100%;  height: 400px;'
            >
            <GmapPolyline
              :path="path"
              ref="polyline"
              v-bind:options="{ strokeColor:'#7367f0'}"
            />
              <div
                :key="indexV"
                v-for="(vehicle, indexV) in data"
              >
                <GmapMarker
                  :key="indexL"
                  v-for="(location, indexL) in vehicle.locations"
                  :position="location.position"
                  :clickable="true"
                  @click="openWindow(indexV, indexL, location.position)"
                >
                  <GmapInfoWindow
                    @closeclick="location.window=false" 
                    :opened="location.window"
                  >
                    <h5>{{ vehicle.name }} </h5>
                    <b-button
                      id="realod"
                      variant="gradient-primary"
                      size="sm"
                      v-if="previousLocationArrow"
                      @click="moveLocation(indexV, indexL, 'previous')"
                    >
                      <feather-icon icon="ArrowLeftIcon"/>
                    </b-button>
                    <b-button
                      id="realod"
                      variant="gradient-primary"
                      size="sm"
                      v-if="nextLocationArrow"
                      @click="moveLocation(indexV, indexL, 'next')"
                    >
                      <feather-icon icon="ArrowRightIcon"/>
                    </b-button>
                    <hr>
                    <p><b>Última conexión:</b> {{ $moment.unix(location.lastConnection).format('DD/MM/YYYY h:mm a') }}</p>
                    <p><b>Batería:</b> {{ location.battery }}%</p>
                    <p v-if="location.speed > 0"><b>Velocidad:</b> {{ location.speed }} km/h</p> 
                    <p v-else><b>Tiempo parado:</b> {{ location.stopTimeMinute }}</p>
                    <p><b>Estatus:</b> {{ location.status }}</p>
                    <hr>
                    <p><a 
                      :href="'https://www.google.com/maps/dir/?api=1&destination=' + location.position.lat + ','+ location.position.lng" target="_blank">Ir al sitio</a></p>
                  </GmapInfoWindow>
                </GmapMarker>
              </div>
            </GmapMap>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
  
</template>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>

br {
  user-select: none;
}

</style>

<script>
import {
  BCard, BCardText, BCardBody, BCardTitle, BCardSubTitle, 
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, 
  BButton, BAlert, BTable, BOverlay, VBTooltip, BPagination, BTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import data from '@/router/routes/ui-elements'


// import utils from '../../utils/utils.js';



export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    vSelect,
    BTable,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BPagination,
    BTooltip,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {

      // Vehicles
      vehicles: [],
      idVehicle: null,
      initialDate: new Date(),
      finalDate: new Date(),

      // Maps
      lastIndexV: 0,
      lastIndexL: 0,
      center: {
        lat: 25.78590,
        lng: -100.18795,
      },
      currentPlace: null,
      places: [],
      overlayStatus: true,
      countDown: 60,

      // Marker
      markerOptions: {
        url: require('../../../assets/images/vehicles/gps/pickup.png'),
        scaledSize: {width: 50, height: 50, f: 'px', b: 'px',},
      },
      previousLocationArrow: false,
      nextLocationArrow: true,

      // Data
      data: [],

      // BEST ROUTE
      origin: {
        lat: null,
        lng: null
      },
      destination: {
        lat: null,
        lng: null
      },
      path: []
    }
  },
  created(){
    this.getVehicles();

  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  methods: {
    getVehicles() {
      this.$axios.get(this.$services + 'gps/get_vehicles').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.vehicles = res.data.data;
          this.idVehicle = this.vehicles[0].id;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.overlayStatus = false;
      });
    },
    getHistoryLocations() {
      this.markers = [];
      this.places = [];
      this.path = [];
      this.previousLocationArrow = false;

      this.overlayStatus = true;
      this.$axios.get(this.$services + 'gps/get_location', {
        params: {
          idVehicle: this.idVehicle,
          initialDate: (this.$moment(this.initialDate).set({hour:0,minute:0,second:0,millisecond:0}).valueOf())/1000,
          finalDate: (this.$moment(this.finalDate).set({hour:23,minute:59,second:59,millisecond:0}).valueOf())/1000,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {

          if (res.data.data.length > 1) {

            // Set Data
            this.data = res.data.data;


            this.data.forEach(element => {
              element.locations.forEach(location => {
                this.path.push(location.position);
              });
            });


            // First position
            this.center = this.data[0].locations[0].position;
            this.data[0].locations[0].window = true;

            // BEST ROUTE
            // Set Oriring
            // this.origin.lat = this.data.locations[0].latitude;
            // this.origin.lng = this.data.locations[0].longitude;

            // Set Destination
            // this.destination.lat = this.data.locations[this.data.locations.length - 1].latitude;
            // this.destination.lng = this.data.locations[this.data.locations.length - 1].longitude;

            // Set Waypoints
            // this.data.locations.forEach(element => {
            //   this.path.push({
            //     lat: element.latitude,
            //     lng: element.longitude,
            //   });

            //   const marker = {
            //     lat: element.latitude,
            //     lng: element.longitude,
            //   };
            //   this.markers.push({ position: marker });
            //   this.places.push(this.currentPlace);
            //   this.center = marker;
            //   this.currentPlace = null;
            // });
          } else {
            this.emptyRoute();
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.overlayStatus = false;
      });
    },
    emptyRoute() {
      this.$swal({
        title: '¡Sin información!',
        text: "No hay rutas registradas en este rango de fechas.",
        icon: 'error',
        confirmButtonText: 'Entendido',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      });
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      this.data.locations.forEach(element => {
        // console.log(element.latitude, element.longitude)
        const marker = {
          lat: element.latitude,
          lng: element.longitude,
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      });
    },
    openWindow(indexV, indexL, position) {
      
      // Change position
      if (this.lastIndexV != null || this.lastIndexL != null)
        this.data[this.lastIndexV].locations[this.lastIndexL].window = false;

      this.lastIndexV = indexV;
      this.lastIndexL = indexL;

      this.data[indexV].locations[indexL].window = true;
      // this.center = position;

      // Set arrows
      this.nextLocationArrow = true;
      this.previousLocationArrow = true;

      // Logical arrows
      if (indexV >= this.data.length - 1) {
        this.nextLocationArrow = false;
      } else if (indexV <= 0) {
        this.previousLocationArrow = false;
      }
    },
    moveLocation(indexV, indexL, action){
      this.lastIndexV = indexV;
      this.lastIndexL = indexL;

      if (action == 'previous')
        indexV--;
      else if (action == 'next')
        indexV++;

      this.openWindow(indexV, indexL, this.data[indexV].locations[indexL].position);
    },
    getRoute: function() { // BEST ROUTE NO SE USA AHORITA
      var directionsService = new google.maps.DirectionsService;
      var directionsDisplay = new google.maps.DirectionsRenderer;
      directionsDisplay.setMap(this.$refs.map.$mapObject);

      //google maps API's direction service
      directionsService.route({
        origin: this.origin,
        destination: this.destination,
        // waypoints: this.waypoints,
        travelMode: 'DRIVING'
      }, function(response, status) {
        if (status === 'OK') {
          directionsDisplay.setDirections(response);
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      });
    }
  },
}
</script>
