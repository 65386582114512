var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-overlay',{attrs:{"show":_vm.overlayStatus,"rounded":"sm"}},[_c('b-card',[_c('b-card-title',{staticClass:"card-header"},[_c('h2',[_vm._v("Vehículos en tiempo real")])]),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"mb-2",attrs:{"lg":"5","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Vehículo","label-for":"i-idVehicle"}},[_c('v-select',{attrs:{"label":"name","options":_vm.vehicles,"clearable":false,"reduce":function (name) { return name.id; }},model:{value:(_vm.idVehicle),callback:function ($$v) {_vm.idVehicle=$$v},expression:"idVehicle"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"lg":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Fecha inicial","label-for":"i-initialDate"}},[_c('flat-pickr',{staticClass:"form-control invoice-edit-input",model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"lg":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Fecha final","label-for":"i-finalDate"}},[_c('flat-pickr',{staticClass:"form-control invoice-edit-input",model:{value:(_vm.finalDate),callback:function ($$v) {_vm.finalDate=$$v},expression:"finalDate"}})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{staticClass:"btn-icon",staticStyle:{"margin-top":"2em"},attrs:{"id":"realod","variant":"gradient-primary","size":"sm"},on:{"click":function($event){return _vm.getHistoryLocations()}}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),(_vm.data.length > 0)?_c('GmapMap',{ref:"map",staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":17,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUI: false
              }}},[_c('GmapPolyline',{ref:"polyline",attrs:{"path":_vm.path,"options":{ strokeColor:'#7367f0'}}}),_vm._l((_vm.data),function(vehicle,indexV){return _c('div',{key:indexV},_vm._l((vehicle.locations),function(location,indexL){return _c('GmapMarker',{key:indexL,attrs:{"position":location.position,"clickable":true},on:{"click":function($event){return _vm.openWindow(indexV, indexL, location.position)}}},[_c('GmapInfoWindow',{attrs:{"opened":location.window},on:{"closeclick":function($event){location.window=false}}},[_c('h5',[_vm._v(_vm._s(vehicle.name)+" ")]),(_vm.previousLocationArrow)?_c('b-button',{attrs:{"id":"realod","variant":"gradient-primary","size":"sm"},on:{"click":function($event){return _vm.moveLocation(indexV, indexL, 'previous')}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}})],1):_vm._e(),(_vm.nextLocationArrow)?_c('b-button',{attrs:{"id":"realod","variant":"gradient-primary","size":"sm"},on:{"click":function($event){return _vm.moveLocation(indexV, indexL, 'next')}}},[_c('feather-icon',{attrs:{"icon":"ArrowRightIcon"}})],1):_vm._e(),_c('hr'),_c('p',[_c('b',[_vm._v("Última conexión:")]),_vm._v(" "+_vm._s(_vm.$moment.unix(location.lastConnection).format('DD/MM/YYYY h:mm a')))]),_c('p',[_c('b',[_vm._v("Batería:")]),_vm._v(" "+_vm._s(location.battery)+"%")]),(location.speed > 0)?_c('p',[_c('b',[_vm._v("Velocidad:")]),_vm._v(" "+_vm._s(location.speed)+" km/h")]):_c('p',[_c('b',[_vm._v("Tiempo parado:")]),_vm._v(" "+_vm._s(location.stopTimeMinute))]),_c('p',[_c('b',[_vm._v("Estatus:")]),_vm._v(" "+_vm._s(location.status))]),_c('hr'),_c('p',[_c('a',{attrs:{"href":'https://www.google.com/maps/dir/?api=1&destination=' + location.position.lat + ','+ location.position.lng,"target":"_blank"}},[_vm._v("Ir al sitio")])])],1)],1)}),1)})],2):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }